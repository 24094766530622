import { Card, Col, Flex, Layout, Row, Typography } from "antd"

export const HomePartner = () => {
    const url = 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/homepage'
    const partners = [
        `logo_골든베어-1.jpg`,
        `logo_골든베어.jpg`,
        `logo_구구스.jpg`,
        `logo_글로리서울안과.jpg`,
        `logo_레이디가구.jpg`,
        `logo_리딩앤-1.jpg`,
        `logo_리딩앤.jpg`,
        `logo_매디필.jpg`,
        `logo_봄툰.jpg`,
        `logo_시원스쿨랩-1.jpg`,
        `logo_시원스쿨랩.jpg`,
        `logo_아이스버그.jpg`,
        `logo_어메이징크리.jpg`,
        `logo_에피그램.jpg`,
        `logo_자만경.jpg`,
        `logo_잭니클라우스-1.jpg`,
        `logo_잭니클라우스.jpg`,
        `logo_코오롱 세이브 프라자.jpg`,
        `logo_클룹.jpg`,
        `logo_한국산업인력공단.jpg`,
        `logo_헬피.jpg`,
        `logo_환경부.jpg`,
        `logo_vwx.jpg`,
        `logo_yes24.jpg`,
        `the_cart_golf.png`,
        `gfore.png`,
        `waac.png`,
        `series.png`,
        `cambridge.png`,
        `customellow.png`,
        `birdie.png`,
        `head.png`,
        `kcf.png`,
        `kt.png`,
        `ncsoft.png`,
        `macallan.png`,
        `galleria.png`,
        `henrycotten.png`,
        `meyer.png`,
        `mr_pizza.png`,
        `samjin.png`,
        `elago.png`,
        `brentwood.png`,
        `vietjet.png`,
        `megabox.png`,
    ]

    return (
        <Row className="home-partner">
            <Col span={24}>
                <Flex justify="center">
                    <Typography.Text style={{ fontSize: 50, fontWeight: 700, color: '#fff' }}>
                        <Typography.Text style={{ fontSize: 50, fontWeight: 700, color: '#F8B31A' }}>
                            {'P'}
                        </Typography.Text>
                        artners
                    </Typography.Text>
                </Flex>
            </Col>
            <Col span={24} style={{ marginTop: 45, }}>
                <Layout.Content>
                    <Row gutter={[16, 16]}>
                        {partners.map((d, idx: number) => {
                            return (
                                <Col key={idx} {...{ xs: 24, sm: 24, md: 12, lg: 4 }}>
                                    <Card
                                        hoverable
                                        className={`item-container`}
                                        data-aos="fade-up"
                                    >
                                        <img
                                            src={`${url}/${d}`}
                                            style={{
                                                objectFit: 'cover'
                                            }}
                                            alt="레퍼런스 이미지"
                                        />
                                    </Card>
                                </Col>
                            )
                        })}
                    </Row>
                </Layout.Content>
            </Col>
        </Row>
    )
}