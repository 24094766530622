import { Divider, Layout } from "antd"
import { MainLayout } from "../../layout/MainLayout"
import { MenuType } from "../../type/MenuType"
import { CoreValue } from "./CoreValue"
import { Header } from "./Header"
import { History } from "./History"
import { GrowUp } from "./GrowUp"
import { Solution } from "./Solution"

export const WhoWeAre = () => {
    return (
        <MainLayout menuType={MenuType.WhoWeAre}>
            <Layout.Content className="who-we-are">
                <Header />
                <GrowUp />
                <Divider
                    style={{
                        borderWidth: 4,
                        borderColor: '#F8B31A'
                    }}
                />
                <Solution />
                <Divider
                    style={{
                        borderWidth: 4,
                        borderColor: '#F8B31A'
                    }}
                />
                <CoreValue />
                {/* <Divider
                    style={{
                        borderWidth: 4,
                        borderColor: '#F8B31A'
                    }}
                /> */}
                {/* <History /> */}
            </Layout.Content>
        </MainLayout>
    )
}