import { Col, Flex, Layout, Row, Space, Typography } from "antd";
import { GrowUpCounter } from "./GrowUpCounter";

export const GrowUp = () => {
    const organizations = [
        {
            target: 2,
            suffix: 'x',
            content: '설립 이후\n 연평균 100% 성장률 달성!',
        },
        {
            target: 30,
            suffix: '+',
            content: '퍼포먼스, 크리에이티브, 개발까지\n준비된 30명 이상의 전문 인력.',
        },
        {
            target: 100,
            suffix: '+',
            content: '100건 이상의 캠페인 수행으로\n갖춰진 운영 노하우',
        },
    ]

    return (
        <Row className="grow-up">
            <Col span={24}>
                <Row>
                    <Col {...{ xs: 0, md: 0, lg: 24 }}>
                        <Flex justify="center">
                            <Space>
                                <Typography.Title level={1} style={{ fontSize: 50, fontWeight: 700, color: '#fff' }}>
                                    We Are Keep
                                </Typography.Title>
                                <Typography.Title level={1} style={{ fontSize: 50, fontWeight: 700, color: '#F8B31A' }}>
                                    Growing!
                                </Typography.Title>
                            </Space>
                        </Flex>
                    </Col>
                    <Col {...{ xs: 24, md: 24, lg: 0 }}>
                        <Flex justify="center">
                            <Space direction="vertical" style={{ width: '100%', textAlign: 'center' }}>
                                <Typography.Title level={1} style={{ fontSize: 50, fontWeight: 700, color: '#fff' }}>
                                    We Are Keep
                                </Typography.Title>
                                <Typography.Title level={1} style={{ fontSize: 50, fontWeight: 700, color: '#F8B31A' }}>
                                    Growing!
                                </Typography.Title>
                            </Space>
                        </Flex>
                    </Col>
                </Row>
            </Col>
            <Col span={24} style={{ marginTop: 45, }}>
                <Layout.Content>
                    <Row gutter={[12, 36]} justify={'center'}>
                        {organizations.map((d, idx: number) => {
                            return (
                                <Col key={idx} {...{ xs: 24, md: 10, lg: 7, xl: 7, xxl: 7 }}>
                                    <Space size={32} direction="vertical" style={{ width: '100%', }}>
                                        <GrowUpCounter
                                            target={d.target}
                                            suffix={d.suffix}
                                        />
                                        <Typography.Paragraph style={{ textAlign: 'center' }}>
                                            <Typography.Text style={{ fontSize: 16, color: '#ccc', whiteSpace: 'pre-wrap' }}>
                                                {d.content}
                                            </Typography.Text>
                                        </Typography.Paragraph>
                                    </Space>
                                </Col>
                            )
                        })}
                    </Row>
                </Layout.Content>
            </Col>
        </Row>
    )
}