import { Col, Row, Space } from "antd"
import { AnimationControls, motion, useAnimationControls } from 'framer-motion'
import React, { FunctionComponent, useEffect, useState } from "react"

export const HomeHeader = () => {
    const firstControls = useAnimationControls()
    const firstHorizonControls = useAnimationControls()
    const secondControls = useAnimationControls()
    const secondHorizonControls = useAnimationControls()

    const [index, setIndex] = useState<number>(0)
    let timer: number
    const innerHeight = window.innerHeight

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        timer = window.setInterval(() => {
            setIndex(index === 0 ? 1 : 0)
        }, 5000)

        switch (index) {
            case 0:
                firstControls.start({ y: `${100}%`, color: '#fff', opacity: 1 })
                firstHorizonControls.start({ x: '0%', color: '#F8B31A', opacity: 1 })
                secondControls.set({ opacity: 0 })
                secondHorizonControls.set({ opacity: 0 })
                break
            case 1:
                secondControls.start({ y: '0%', color: '#fff', opacity: 1 })
                secondHorizonControls.start({ x: '0%', color: '#F8B31A', opacity: 1 })
                firstControls.set({ opacity: 0 })
                firstHorizonControls.set({ opacity: 0 })
                break
        }

        return () => {
            window.clearInterval(timer)
        }
    }, [index])

    return (
        <div
            className="home-header"
            style={{
                width: '100%',
                position: 'relative',
            }}
        >
            <Row>
                <Col {...{ xs: 0, sm: 0, md: 0, lg: 24 }}>
                    <div className={'typhography'}>
                        <div className="typhography-wrapper" style={{ top: window.innerHeight * 0.3, left: 55 }}>
                            <Space size={55} direction="vertical">
                                <Space direction="vertical" className="title">
                                    <Space size={2} wrap>
                                        {Array.from('Think Different').map((w, idx) => {
                                            return (
                                                <Raise key={idx} wordIndex={idx} controls={firstControls}>
                                                    <span dangerouslySetInnerHTML={{ __html: w.replace(' ', '&nbsp;') }}></span>
                                                </Raise>
                                            )
                                        })}
                                    </Space>
                                    <Space size={2} wrap>
                                        {Array.from('More Professional').map((w, idx) => {
                                            return (
                                                <Raise key={idx} wordIndex={idx} controls={firstControls}>
                                                    <span dangerouslySetInnerHTML={{ __html: w.replace(' ', '&nbsp;') }}></span>
                                                </Raise>
                                            )
                                        })}
                                    </Space>
                                </Space>
                                <Space direction="vertical" className="subtitle">
                                    <Space size={2} wrap>
                                        <Horizon wordIndex={0} weight={-1} duration={0.5} color="#F8B31A" controls={firstHorizonControls}>
                                            <span>생각하는늑대는 어려운 것을 쉽게, 쉬운 것을 깊게,</span>
                                        </Horizon>
                                    </Space>
                                    <Space size={2} wrap>
                                        <Horizon wordIndex={1} weight={-1} duration={0.5} color="#F8B31A" controls={firstHorizonControls}>
                                            <span>깊은 것을 유쾌하게 풀어내는 디지털 마케팅 에이전시 입니다.</span>
                                        </Horizon>
                                    </Space>
                                </Space>
                            </Space>
                        </div>
                    </div>
                </Col>
                <Col {...{ xs: 0, sm: 0, md: 0, lg: 24 }}>
                    <div className={'typhography'}>
                        <div className="typhography-wrapper" style={{ top: window.innerHeight - 400, right: 55, textAlign: 'end' }}>
                            <Space size={55} direction="vertical">
                                <Space direction="vertical" className="title">
                                    <Space size={2} wrap>
                                        {Array.from('Someone can breathe comfortably').map((w, idx) => {
                                            return (
                                                <Raise key={idx} wordIndex={idx} initial={0} end={100} controls={secondControls}>
                                                    <span dangerouslySetInnerHTML={{ __html: w.replace(' ', '&nbsp;') }}></span>
                                                </Raise>
                                            )
                                        })}
                                    </Space>
                                    <Space size={2} wrap>
                                        {Array.from('with our presence.').map((w, idx) => {
                                            return (
                                                <Raise key={idx} wordIndex={idx} initial={0} end={100} controls={secondControls}>
                                                    <span dangerouslySetInnerHTML={{ __html: w.replace(' ', '&nbsp;') }}></span>
                                                </Raise>
                                            )
                                        })}
                                    </Space>
                                </Space>
                                <Space direction="vertical" className="subtitle">
                                    <Space size={2} wrap>
                                        <Horizon wordIndex={0} weight={1} duration={0.5} color="#F8B31A" controls={secondHorizonControls}>
                                            <span>생각하는늑대와 함께 하는 모든 파트너사와 동료들이</span>
                                        </Horizon>
                                    </Space>
                                    <Space size={2} wrap>
                                        <Horizon wordIndex={1} weight={1} duration={0.5} color="#F8B31A" controls={secondHorizonControls}>
                                            <span>우리의 존재만으로 편안히 삶을 영위할 수 있도록 하는 것이 우리의 미션입니다 </span>
                                        </Horizon>
                                    </Space>
                                </Space>
                            </Space>
                        </div>
                    </div>
                </Col>
                <Col {...{ xs: 24, sm: 24, md: 24, lg: 0 }}>
                    <div className={`typhography`}>
                        <div className="typhography-wrapper" style={{ top: innerHeight / 2, left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <Space size={55} direction="vertical" style={{ textAlign: 'center' }}>
                                <Space direction="vertical" className="title" style={{ textAlign: 'center', lineHeight: '24px' }}>
                                    <Space size={2} wrap>
                                        {Array.from('Think Different').map((w, idx) => {
                                            return (
                                                <Raise key={idx} wordIndex={idx} controls={firstControls}>
                                                    <span dangerouslySetInnerHTML={{ __html: w.replace(' ', '&nbsp;') }} style={{ fontSize: 24 }}></span>
                                                </Raise>
                                            )
                                        })}
                                    </Space>
                                    <Space size={2} wrap>
                                        {Array.from('More Professional').map((w, idx) => {
                                            return (
                                                <Raise key={idx} wordIndex={idx} controls={firstControls}>
                                                    <span dangerouslySetInnerHTML={{ __html: w.replace(' ', '&nbsp;') }} style={{ fontSize: 24 }}></span>
                                                </Raise>
                                            )
                                        })}
                                    </Space>
                                </Space>
                                <Space direction="vertical" className="subtitle" style={{ textAlign: 'center' }}>
                                    <Space size={2} wrap>
                                        <Horizon wordIndex={0} duration={0.5} weight={0} color="#F8B31A" controls={firstHorizonControls}>
                                            <span>생각하는늑대는 어려운 것을 쉽게,</span>
                                        </Horizon>
                                    </Space>
                                    <Space size={2} wrap>
                                        <Horizon wordIndex={1} duration={0.5} weight={0} color="#F8B31A" controls={firstHorizonControls}>
                                            <span>쉬운 것을 깊게,</span>
                                        </Horizon>
                                    </Space>
                                    <Space size={2} wrap>
                                        <Horizon wordIndex={2} duration={0.5} weight={0} color="#F8B31A" controls={firstHorizonControls}>
                                            <span>깊은 것을 유쾌하게 풀어내는</span>
                                        </Horizon>
                                    </Space>
                                    <Space size={2} wrap>
                                        <Horizon wordIndex={3} duration={0.5} weight={0} color="#F8B31A" controls={firstHorizonControls}>
                                            <span>디지털 마케팅 에이전시 입니다.</span>
                                        </Horizon>
                                    </Space>
                                </Space>
                            </Space>
                        </div>
                    </div>
                </Col>
                <Col {...{ xs: 24, sm: 24, md: 24, lg: 0 }}>
                    <div className={'typhography'}>
                        <div className="typhography-wrapper" style={{ top: innerHeight / 2, left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <Space size={55} direction="vertical" style={{ textAlign: 'center' }}>
                                <Space direction="vertical" className="title" style={{ textAlign: 'center', lineHeight: '24px' }}>
                                    <Space size={2} wrap>
                                        {Array.from('Someone can').map((w, idx) => {
                                            return (
                                                <Raise key={idx} wordIndex={idx} initial={0} weight={0} end={0} controls={secondControls}>
                                                    <span dangerouslySetInnerHTML={{ __html: w.replace(' ', '&nbsp;') }} style={{ fontSize: 24 }}></span>
                                                </Raise>
                                            )
                                        })}
                                    </Space>
                                    <Space size={2} wrap>
                                        {Array.from('breathe comfortably').map((w, idx) => {
                                            return (
                                                <Raise key={idx} wordIndex={idx} initial={0} end={0} controls={secondControls}>
                                                    <span dangerouslySetInnerHTML={{ __html: w.replace(' ', '&nbsp;') }} style={{ fontSize: 24 }}></span>
                                                </Raise>
                                            )
                                        })}
                                    </Space>
                                    <Space size={2} wrap>
                                        {Array.from('with our presence.').map((w, idx) => {
                                            return (
                                                <Raise key={idx} wordIndex={idx} initial={0} end={0} controls={secondControls}>
                                                    <span dangerouslySetInnerHTML={{ __html: w.replace(' ', '&nbsp;') }} style={{ fontSize: 24, lineHeight: '12px' }}></span>
                                                </Raise>
                                            )
                                        })}
                                    </Space>
                                </Space>
                                <Space direction="vertical" className="subtitle" style={{ textAlign: 'center' }}>
                                    <Space size={2} wrap>
                                        <Horizon wordIndex={0} duration={0.5} weight={0} color="#F8B31A" controls={secondHorizonControls}>
                                            <span>생각하는늑대와 함께 하는</span>
                                        </Horizon>
                                    </Space>
                                    <Space size={2} wrap>
                                        <Horizon wordIndex={1} duration={0.5} weight={0} color="#F8B31A" controls={secondHorizonControls}>
                                            <span>모든 파트너사와 동료들이</span>
                                        </Horizon>
                                    </Space>
                                    <Space size={2} wrap>
                                        <Horizon wordIndex={2} duration={0.5} weight={0} color="#F8B31A" controls={secondHorizonControls}>
                                            <span>우리의 존재만으로 편안히 삶을 영위할 수</span>
                                        </Horizon>
                                    </Space>
                                    <Space size={2} wrap>
                                        <Horizon wordIndex={3} duration={0.5} weight={0} color="#F8B31A" controls={secondHorizonControls}>
                                            <span>있도록 하는 것이 우리의 미션입니다 </span>
                                        </Horizon>
                                    </Space>
                                </Space>
                            </Space>
                        </div>
                    </div>
                </Col>
                <Col
                    span={24}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: '#000',
                        opacity: 0.4,
                        zIndex: 5,
                    }}
                />
                <Col
                    span={24}
                >
                    <video
                        loop
                        muted
                        autoPlay={true}
                        controls={false}
                        playsInline={true}
                        style={{
                            width: '100%',
                            height: window.innerHeight,
                            objectFit: 'cover',
                            zIndex: 1,
                            marginTop: -2,
                        }}
                    >
                        <source src={'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/assets/108482-680697635.mp4'} />
                    </video>
                </Col>
            </Row>
        </div>
    )
}

interface IRaiseprops {
    wordIndex: number
    controls: AnimationControls
    weight?: number
    duration?: number
    color?: string
    initial?: number
    end?: number
    children: React.ReactNode
}

const Raise: FunctionComponent<IRaiseprops> = (props) => {
    const {
        wordIndex,
        controls,
        weight = 1,
        duration = 0.5,
        initial = 100,
        children
    } = props

    return (
        <motion.span
            transition={{
                duration: duration,
                delay: 0.055 * wordIndex,
            }}
            initial={{ y: `${initial * weight}%`, color: 'rgba(0, 0, 0, 0)', opacity: 0 }}
            // animate={{ y: `${end}%`, color: color }}
            animate={controls}
        >
            {children}
        </motion.span>
    );
}

const Horizon: FunctionComponent<IRaiseprops> = (props) => {
    const {
        wordIndex,
        controls,
        weight = 1,
        duration = 0.5,
        children
    } = props

    return (
        <motion.span
            transition={{
                ease: 'linear',
                duration: duration,
                delay: 0.055 * wordIndex,
                staggerChildren: 1,
            }}
            initial={{ x: `${100 * weight}%`, color: 'rgba(0, 0, 0, 0)', opacity: 0 }}
            // animate={{ x: '0%', color: color }}
            animate={controls}
            style={{ display: 'inline-block', whiteSpace: 'pre' }}
        >
            {children}
        </motion.span>
    );
}