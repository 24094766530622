import { Divider, Layout } from "antd"
import { MainLayout } from "../../layout/MainLayout"
import { MenuType } from "../../type/MenuType"
import { HomeHeader } from "./HomeHeader"
import { HomeLocationAndInquiry } from "./HomeLocationAndInquiry"
import { HomeOrganization } from "./HomeOrganization"
import { HomePartner } from "./HomePartner"
import { HomeService } from "./HomeService"

export const Home = () => {
    return (
        <MainLayout menuType={MenuType.Home}>
            <Layout.Content className="home">
                <HomeHeader />
                <HomeService />
                <Divider
                    style={{
                        borderWidth: 4,
                        borderImageSlice: 1,
                        borderImageSource: 'linear-gradient(to right, #F8B31A, #fff)'
                    }}
                />
                <HomeOrganization />
                <Divider
                    style={{
                        borderWidth: 4,
                        borderImageSlice: 1,
                        borderImageSource: 'linear-gradient(to left, #F8B31A, #fff)'
                    }}
                />
                <HomePartner />
                <Divider
                    style={{
                        borderWidth: 4,
                        borderColor: '#F8B31A'
                    }}
                />
                <HomeLocationAndInquiry />
            </Layout.Content>
        </MainLayout>
    )
}