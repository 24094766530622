import { animated, SpringConfig, useSpring } from "@react-spring/web"
import { Space, Typography } from "antd"
import { FunctionComponent } from "react"

interface IProps {
    target: number
    suffix: string
}

export const GrowUpCounter: FunctionComponent<IProps> = (props) => {
    const { target, suffix } = props

    const [{ number }] = useSpring(() => ({
        from: { number: 0 },
        to: { number: target },
        config: (value: number): SpringConfig => {
            if (value < target * 0.95) {
                return { tension: 80, friction: 10 }
            } else {
                return { tension: 40, friction: 30 }
            }
        },
    }))

    return (
        <Space size={2} style={{ width: '100%', justifyContent: 'center' }}>
            <animated.div style={{ fontSize: 56, fontWeight: "bold", color: '#F8B31A', textAlign: 'center' }}>
                {number.to((n: number) => n.toFixed(0))}
            </animated.div>
            <Typography.Paragraph style={{ paddingTop: suffix === '+' ? 4 : 0, marginBottom: suffix === '+' ? '1em' : 0, }}>
                <Typography.Text style={{ fontSize: 50, fontWeight: "bold", color: '#F8B31A', textAlign: 'center' }}>
                    {suffix}
                </Typography.Text>
            </Typography.Paragraph>
        </Space>
    )
}