import { Col, Flex, Row, Space, Typography } from "antd"

export const Header = () => {
    const height = window.innerHeight
    const width = window.innerWidth

    return (
        <Row className="header">
            <Col
                span={24}
                className="parallax"
                style={{
                    height: width > 576 ? height * 0.5 : height,
                    backgroundPosition: 'center',
                }}
            >
                <Row>
                    <Col
                        span={24}
                        style={{
                            height: width > 576 ? height / 2 : height,
                            alignContent: 'center'
                        }}
                    >
                        <Flex justify="center" align="center" style={{ height: '100%' }}>
                            <Space size={'large'} direction="vertical" style={{ textAlign: 'center' }}>
                                <Typography.Title level={1} style={{ color: '#fff', fontSize: 58, }}>
                                    Our Mission
                                </Typography.Title>
                                <Typography.Title level={4} style={{ color: '#fff', fontWeight: 700, fontSize: 28, }}>
                                    우리의 존재로, <br />
                                    누군가는 편히 숨쉴 수 있다.
                                </Typography.Title>
                            </Space>
                        </Flex>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}