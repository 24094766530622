import { Card, Col, Flex, Layout, Row, Space, Typography } from "antd"

export const HomeOrganization = () => {
    const url = 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/homepage'
    const organizations = [
        {
            title: 'Our\nTeam',
            content: '최고의 팀플레이를 만들어내는 생각하는 늑대',
        },
        {
            title: 'Performance',
            content: '디지털캠페인 운영 및 집행\n신규 매체 발굴 및 미디어 믹스 제안\n마케팅 KPI 관리',
            background: `${url}/campaign.jpg`,
            items: [
                '퍼포먼스 1팀',
                '퍼포먼스 2팀',
                '퍼포먼스 3팀',
                '퍼포먼스 4팀',
            ]
        },
        {
            title: 'AI Content',
            content: '종합 캠페인 기획 및 운영\nSNS 채널 운영 및 콘텐츠 기획/제작\n디자인,영상 및 작화 창작',
            background: `${url}/design.jpg`,
            items: [
                '브랜드 기획',
                '소셜 마케팅',
                '미디어 제휴',
                '크리에이티브&디자인',
            ]
        },
        {
            title: 'Support',
            content: 'IT / AI / R&D / UI & UX\n마케팅 솔루션 & 마케팅 매체사 SDK 설치 및 유지보수 지원',
            background: `${url}/support.jpg`,
            items: [
                '개발',
                'UI & UX',
            ]
        },
    ]

    return (
        <Row className="home-organization">
            <Col span={24}>
                <Flex justify="center">
                    <Typography.Text style={{ fontSize: 50, fontWeight: 700, color: '#fff' }}>
                        <Typography.Text style={{ fontSize: 50, fontWeight: 700, color: '#F8B31A' }}>
                            {'O'}
                        </Typography.Text>
                        rganization
                    </Typography.Text>
                </Flex>
            </Col>
            <Col span={24} style={{ marginTop: 45, }}>
                <Layout.Content>
                    <Row gutter={[12, 12]} justify={'center'}>
                        {organizations.map((d, idx: number) => {
                            return (
                                <Col
                                    key={d.title}
                                    {...{ xs: 24, sm: 24, md: 24, lg: 10 }}
                                    data-aos="flip-up"
                                >
                                    <Card
                                        hoverable
                                        key={d.title}
                                        className={`item-container ${d.background ? '' : 'no-background'}`}
                                    >
                                        <Row className="item">
                                            <Col span={24}>
                                                <Space direction="vertical">
                                                    <Typography.Text className="title" style={{ fontSize: 36 }}>
                                                        {d.title}
                                                    </Typography.Text>
                                                    <Typography.Text className="content" style={{ letterSpacing: "-0.5px" }}>
                                                        <span dangerouslySetInnerHTML={{ __html: d.content }} />
                                                    </Typography.Text>
                                                </Space>

                                                <Typography.Paragraph style={{ marginTop: 12, }}>
                                                    <ul>
                                                        {d.items?.map((a) => {
                                                            return (
                                                                <li key={a}>
                                                                    {a}
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </Typography.Paragraph>
                                            </Col>
                                        </Row>
                                        {d.background && (
                                            <img
                                                src={d.background}
                                                style={{
                                                    top: 0,
                                                    bottom: 0,
                                                    left: 0,
                                                    right: 0,
                                                    zIndex: 1,
                                                    width: '100%',
                                                    position: 'absolute',
                                                    objectFit: 'cover',
                                                    objectPosition: 'center',
                                                }}
                                                alt="회사의 목표 이미지"
                                            />
                                        )}
                                    </Card>
                                </Col>
                            )
                        })}
                    </Row>
                </Layout.Content>
            </Col>
        </Row>
    )
}