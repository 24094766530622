import { Card, Col, Flex, Layout, Row, Space, Typography } from "antd";

export const Solution = () => {
    const organizations = [
        {
            content: '대량 작업 센터',
            image: 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/homepage/solutions/bulk.png',
        },
        {
            content: '모니터링 센터',
            image: 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/homepage/solutions/keyword.png',
        },
        {
            content: '매체 크롤링 및 분석',
            image: 'https://thinkingwolf.s3.ap-northeast-2.amazonaws.com/homepage/solutions/crawler.png',
        },
    ]

    return (
        <Row className="solution background">
            <Col span={24} style={{ marginTop: 45, zIndex: 10, }}>
                <Flex justify="center">
                    <Space direction="vertical" style={{ textAlign: 'center' }}>
                        <Space size={1}>
                            <Typography.Title level={1} style={{ fontSize: 50, fontWeight: 700, color: '#F8B31A' }}>
                                S
                            </Typography.Title>
                            <Typography.Title level={1} style={{ fontSize: 50, fontWeight: 700, color: '#fff' }}>
                                olution
                            </Typography.Title>
                        </Space>
                        <Typography.Text style={{ fontSize: 18, color: '#fff' }}>
                            캠페인 효율을 부스팅 할 수 있는 <br />
                            <Typography.Text strong style={{ fontSize: 18, color: '#F8B31A' }}>
                                자체 개발 솔루션을 보유
                            </Typography.Text>
                            하고 있습니다!
                        </Typography.Text>
                    </Space>
                </Flex>
            </Col>
            <Col span={24} style={{ marginTop: 45, zIndex: 10, }}>
                <Layout.Content>
                    <Row gutter={[24, 36]} justify={'center'}>
                        {organizations.map((d, idx: number) => {
                            return (
                                <Col key={idx} {...{ xs: 24, md: 10, lg: 7, xl: 7, xxl: 7 }} className="item-container">
                                    <Card>
                                        <Typography.Text>
                                            {d.content}
                                        </Typography.Text>
                                        {d.image && (
                                            <img
                                                src={d.image}
                                                alt="생각하는늑대 솔루션 이미지"
                                            />
                                        )}
                                    </Card>
                                </Col>
                            )
                        })}
                    </Row>
                </Layout.Content>
            </Col>
            <Col
                span={24}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: '#000',
                    opacity: 0.6,
                    zIndex: 5,
                }}
            />
        </Row>
    )
}